import React, { useState, useEffect } from 'react';
import { Routes, Route, BrowserRouter as Router, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Header from "./Components/Header/Header";
import Sidebar from './Components/Sidebar/Sidebar';
import CommentPage from './Components/CommentPage/CommentPage'; // 未使用なので、必要ならどこかで使ってください。
import MainContent from './Components/MainContent/MainContent';
import CreateThread from './Components/CreateThread/CreateThread';
import HeaderComment from "./Components/Header/Header_Comment"; 
// App.js または ルーティングを管理している.jsファイル
import AboutUs from './Components/SidePage/AboutUs';
import WarningsPage from './Components/SidePage/WarningsPage';
import ContactUs from './Components/SidePage/ContactUs';
import ReactGA from 'react-ga4';
import { DisplayAd, InArticleAd, MultipleAd } from './Components/Adsense/AdComponent';

ReactGA.initialize(process.env.REACT_APP_API_GOOGLE_ANALYTICS);
const AppWrapper=() => (
  <Router>
    <App />
  </Router>
);

const App = () => {
    // モバイル表示のブレークポイント
  const mobileBreakpoint = 768;
  const [showCreateThread, setShowCreateThread] = useState(false);
  const [selectedGenre, setSelectedGenre] = useState("Home");
   const [searchedArticles, setSearchedArticles] = useState([]); // 検索結果を保持するための状態
  const location = useLocation(); 
  const handleOpenCreateThread = () => {
    setShowCreateThread(true);
  };

  const handleCloseCreateThread = () => {  
    setShowCreateThread(false);
  };


  // ビューポートの幅に基づいてサイドバーの表示状態を設定
  const [isSidebarVisible, setIsSidebarVisible] = useState(window.innerWidth > mobileBreakpoint);

  // ウィンドウのリサイズイベントに応じてサイドバーの表示状態を更新
  useEffect(() => {
    const handleResize = () => {
      setIsSidebarVisible(window.innerWidth > mobileBreakpoint);
    };

    // ウィンドウのリサイズイベントにリスナーを追加
    window.addEventListener('resize', handleResize);

    // コンポーネントのアンマウント時にリスナーをクリーンアップ
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);




  useEffect(() => {
    // ロケーションが変わるたびにページビューを送信
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  useEffect(() => {
  }, [showCreateThread]);
  const isCommentPage = location.pathname.startsWith('/comment/');
  return (
    <div className="app">
      {isCommentPage ? 
        <HeaderComment /> : 
        <Header 
          onOpenCreateThread={handleOpenCreateThread} 
          setSearchedArticles={setSearchedArticles} // 検索結果を渡す
          isSidebarVisible={isSidebarVisible}
          setIsSidebarVisible={setIsSidebarVisible}
        />
      }     
      <div className="container-fluid">
        <div className="row">
          <div className={isCommentPage ? 'col-0' : 'col-3'}>
            {isCommentPage ? null : <Sidebar setSelectedGenre={setSelectedGenre} 
              isSidebarVisible={isSidebarVisible}
              setIsSidebarVisible={setIsSidebarVisible}
            />}
          </div>
          <div className={isCommentPage ? 'col-12' : 'col-9'}>
            <Routes>
              <Route path="/" element={<MainContent selectedGenre={selectedGenre} searchedArticles={searchedArticles} />} />
              <Route path="/comment/:articleId" element={<CommentPage />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/warnings" element={<WarningsPage />} />
              <Route path="/contact-us" element={<ContactUs />} />
            </Routes>
            
            {showCreateThread && <CreateThread onClose={handleCloseCreateThread} />}
            
          </div>

        </div>
      </div>
    </div>
  );
};

export default AppWrapper;
