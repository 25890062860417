import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; 
import Up_logo from '../assets/Images/thumb_up_blue.svg'; 
import Down_logo from '../assets/Images/thumb_down_red.svg'; 
import Fire_logo from '../assets/Images/local_fire_orange.svg';
import Comment_logo from '../assets/Images/Comment_icon.svg';
import "./MainContent.css"

const MainContent = ({ selectedGenre, searchedArticles }) => {
  const navigate = useNavigate();
  const [articles, setArticles] = useState([]);
  const [error, setError] = useState(null);
  const [reactions, setReactions] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  

  const fetchReactions = async (articleId) => {
    const apiUrlBase = process.env.NODE_ENV === 'development' 
    ? process.env.REACT_APP_API_DEV_URL
    : process.env.REACT_APP_API_URL;
    const reactionApiUrl = `${apiUrlBase}${process.env.REACT_APP_API_ENDPOINT}${articleId}${process.env.REACT_APP_API_ARTICLE_SUMMARY}`;
  
    try {
      
      const response = await fetch(reactionApiUrl);
      //console.log(`Fetching reactions from: ${response}`);
      if (!response.ok) {
        throw new Error(`Server responded with a status of ${response.status}`);
      }
      const contentType = response.headers.get("content-type");
      if (!contentType || !contentType.includes("application/json")) {
        throw new Error(`Expected application/json but received ${contentType || "unknown"}`);
      } 
      const data = await response.json();
      if (data.error) {
        throw new Error(data.error);
      }
      setReactions(prevReactions => ({
        ...prevReactions,
        [articleId]: data,
        
      }));
      return data;
    } catch (err) {
      
      console.error(`Error fetching reactions:`, err);
      return null;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
  
      let apiUrl = process.env.NODE_ENV === 'development' 
      ? `${process.env.REACT_APP_API_DEV_URL}${process.env.REACT_APP_API_ENDPOINT}`
      : `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_ENDPOINT}`;

    // 選択されたジャンルに基づいてAPIのURLを更新
    if (selectedGenre && selectedGenre !== 'Home') {
      apiUrl += `${process.env.REACT_APP_API_ARTICLE_FILTER_GET}${selectedGenre}${process.env.REACT_APP_API_ARTICLE_FILTER_GET_LIMIT}`;
    } else {
      apiUrl += `${process.env.REACT_APP_API_ARTICLE_MAIN_GET}`;
    }
  
      try {
        const response = await fetch(apiUrl);
        const articleData = await response.json();
        
        if (articleData.error) {
          throw new Error(articleData.error);
        }
        
        // Fetch reactions for each article
        const fetchPromises = articleData.map((article) => fetchReactions(article.article_id));
        const reactionsData = await Promise.all(fetchPromises);
        
        setArticles(articleData);
        setReactions(reactionsData.reduce((acc, cur, idx) => {
          acc[articleData[idx].article_id] = cur;
          return acc;
        }, {}));
  
        setIsLoading(false);
      } catch (error) {
        console.error("An error occurred:", error);
        setError("An error occurred while fetching data.");
        setIsLoading(false);
      }
    };
  
    fetchData();
  }, [selectedGenre, searchedArticles]);

  if (isLoading) {
    return <div>Loading...</div>;
  }
// eslint-disable-next-line
  const handleVote = (e, id, type) => {
    e.stopPropagation();
    setArticles(prevArticles =>
      prevArticles.map(article => {
        if (article.id === id) {
          if (type === 'like') article.likes++;
          if (type === 'dislike') article.dislikes++;
          if (type === 'popular') article.popularity++;
        }
        return article;
      })
    );
  };

  const handleContainerClick = (articleId) => {
    navigate(`${process.env.REACT_APP_API_CREATE_COMMENTS}${articleId}`);
  };

  return (
    <div className="col-12 col-md-8 main-content bg-white">
      {error && <div className="error-message">{error}</div>}
      {articles && articles.map((article, index) => (
        <div 
          className="article-container" 
          key={article.article_id} 
          onClick={() => handleContainerClick(article.article_id)}
        >
          <div className="article-title">
            {article.title}
          </div>
          <div className="article-tags-and-date">
            <div className="article-tags">
              {article.tags && article.tags.map((tag, index) => (
                <span key={index} className="article-tag">#{tag}</span>
              ))}
            </div>
            <div className="article-update-date">
              {"Updated on: " + new Date(article.updated_at).toLocaleDateString()}
            </div>
          </div>
          <div className="icon-container">
            <div className="popularity-icon">
              <img src={Up_logo} alt="thumbs-up" width="24" height="24" />
              <span className="popularity-score">{reactions[article.article_id]?.reactionSummary?.agree_count || 0}</span>
            </div>
            <div className="popularity-icon">
              <img src={Down_logo} alt="thumbs-down" width="24" height="24" />
              <span className="popularity-score">{reactions[article.article_id]?.reactionSummary?.disagree_count || 0}</span>
            </div>
            <div className="popularity-icon">
              <img src={Fire_logo} alt="popular" width="24" height="24" />
              <span className="popularity-score">{reactions[article.article_id]?.reactionSummary?.upvote_count || 0}</span>
            </div>
            <div className="comment-icon">
              <img src={Comment_logo} alt="comment" width="24" height="24" />
              <span className="popularity-score">{reactions[article.article_id]?.reactionSummary?.comments_count || 0}</span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MainContent;
