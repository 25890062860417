import React from 'react';
import './WarningsPage.css';
const WarningsPage = () => {
    return (
      <div className="warnings-container">
        <h1>注意事項</h1>
  
        <section className="warning-section">
          <h2>サイト使用にあたって</h2>
          <p>
            このサイトを使用する際の注意事項です。必ずお読みください。<br />
            荒らし・スクリプト対策のためにGoolgeアカウントでの管理を行っています。<br />
            サービス向上・ログ管理以外での情報の利用は行いません。<br />
          </p>
          <h3>■禁止事項</h3>
          <p>
            ・当サイトでの荒らし行為<br />
            ・暴言/レッテル張り/誹謗中傷/第三者を不快にする書き込み<br />
            　他人に迷惑を掛けるのはやめましょう<br />
            ・当サイトの無断転載/スクリプト<br />

          </p>
        </section>
  
        {/* その他のセクション... */}
      </div>
    );
  };

export default WarningsPage;
