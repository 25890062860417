import React from 'react';
import './AboutUs.css';
import up_logo_color from '../assets/Images/thumb_up_blue.svg';
import down_logo_color from '../assets/Images/thumb_down_red.svg';
import high_logo_color from '../assets/Images/local_fire_orange.svg';
const AboutUs = () => {
  return (
    <div className="about-us-container">
    <h1>About Us</h1>

    <section className="about-site">
      <h2>当サイトについて</h2>
      <p>
        議論.comは「世間の賛否両論を可視化する」がコンセプトです。<br />
        匿名で楽しく議論しましょう。<br />

        使用する際は、「注意事項」を読んでから使用してね。<br />
      </p>
      <h2>当サイトについて</h2>
      <p>
        <img src={up_logo_color} alt="Agree" />
        賛成ボタン: 記事のタイトル・１コメに賛成する場合は、このボタンを押してください。<br />
        <img src={down_logo_color} alt="Disagree" />
        反対ボタン： 記事のタイトル・1コメに賛成できない場合は、このボタンをおしてください。<br />
        <img src={high_logo_color} alt="High Rating" />
        高評価ボタン: おもしろい記事だと思ったらこのボタンを押してください。他の利用者に表示されやすくなります。 <br />


      </p>

    </section>

    <section className="about-developer">
      <h2>開発者</h2>
      <p>開発者: hei.</p>
      <a href="https://twitter.com/hei_gironcom" target="_blank" rel="noopener noreferrer">X(Twitter)</a>
      <br />
      <a href="https://www.amazon.jp/hz/wishlist/ls/3KS7PYT0FBI3S?ref_=wl_share" target="_blank" rel="noopener noreferrer">開発支援 Amazon</a>
      
    </section>

    <section className="about-contact">
      <h2>連絡</h2>
      <p>連絡は「連絡先」または開発者XのDMまでお願いします。</p>
    </section>
  </div>
  );
};

export default AboutUs;



