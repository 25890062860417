import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './CreateThread.css';

function CreateThread({ onClose }) {
const [title, setTitle] = useState("");
const [genre, setGenre] = useState("");
const [comment, setComment] = useState("");
// eslint-disable-next-line no-unused-vars
const [isWarningVisible, setIsWarningVisible] = useState(false);
const [charCount, setCharCount] = useState(0);
const navigate = useNavigate();
const userId = localStorage.getItem('userId');
 // eslint-disable-next-line
const [isErrorVisible, setIsErrorVisible] = useState(false);

const checkConditions = () => {
  return {
    isTitleValid: title.trim() !== "",
    isGenreSelected: genre !== "",
    isCommentEntered: charCount > 0,
    isUserLoggedIn: userId && userId !== "undefined"
  };
};
// NGワードリスト
const bannedWords = ["殺す", "ころす", "コロス", "死ね", "チンコ", "ちんこ", "まんこ", "マンコ"];

// サニタライズ関数
const sanitize = (input) => {
  const temp = document.createElement('div');
  temp.textContent = input;
  return temp.innerHTML;
};

// 最大文字数
const MAX_TITLE_LENGTH = 100;
const MAX_COMMENT_LENGTH = 500;

const handleTitleChange = (e) => {
  const newTitle = e.target.value;
  if (bannedWords.some((word) => newTitle.includes(word))) {
    setIsWarningVisible(true);
    return;
  }
  setIsWarningVisible(false);
  setTitle(newTitle);
};
const isButtonEnabled = () => {
  return title.trim() !== "" && 
         genre !== "" && 
         userId && 
         !isWarningVisible && 
         charCount > 0 && 
         charCount <= MAX_COMMENT_LENGTH;
};
const handleCommentChange = (e) => {
  const newComment = e.target.value;
  setCharCount(newComment.length); // 文字数を更新
  if (bannedWords.some((word) => newComment.includes(word))) {
    setIsWarningVisible(true);
    return;
  }
  setIsWarningVisible(false);
  setComment(newComment);
};

const handleSubmit = async () => {
  if (genre === "") {
    console.error('ジャンルが選択されていません。');
    return;
  }
  if (title.trim() === "") {
    console.error('記事タイトルが空です。');
    return;
  }
  if (!userId || userId === "undefined") {
    console.error('ログインしていません。');
    return;
  }
  
  // サニタライズと文字数制限
  const sanitizedTitle = sanitize(title);
  const sanitizedComment = sanitize(comment);
  if (sanitizedTitle.length > MAX_TITLE_LENGTH || sanitizedComment.length > MAX_COMMENT_LENGTH) {
    console.error('タイトルまたはコメントが長すぎます。');
    return;
  }

  // 禁止ワードチェック
  if (bannedWords.some((word) => sanitizedTitle.includes(word)) || bannedWords.some((word) => sanitizedComment.includes(word))) {
    console.error('禁止されたワードが含まれています。');
    return;
  }

  try {
    const jwtToken = localStorage.getItem('jwt');
    let apiUrl;

    // 環境によってAPIのURLを切り替え
    if (process.env.REACT_APP_API_NODE_ENV === 'development') {
      apiUrl = process.env.REACT_APP_API_DEV_URL;
    } else {
      apiUrl = process.env.REACT_APP_API_URL;
    }
    const apiEndpoint = process.env.REACT_APP_API_CREATE_ARTICLE_ENDPOINT;
    const url = `${apiUrl}${apiEndpoint}`;
    
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${jwtToken}`, 
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        title: sanitizedTitle,
        genre,
        comment: sanitizedComment,
        userId
      })
    });

    if (response.status === 201) {
      const data = await response.json();
      const { articleId } = data;
      navigate(`${process.env.REACT_APP_API_CREATE_COMMENTS}${articleId}`);
      onClose();
    }
  } catch (error) {
    console.error('スレッド作成に失敗:', error);
  }





};


const conditions = checkConditions();

return (
  <div className="modal-overlay-thread">
    <div className="modal-thread">
      <div className="modal-content-thread">
        <div className="button-container-thread">
          <button type="button" className="cancel-button-thread" onClick={onClose}>キャンセル</button>
          <button
            type="button"
            className={isButtonEnabled() ? "create-button-thread" : "create-button-thread-disabled"}
            onClick={handleSubmit}
            disabled={!isButtonEnabled()}
            >作成</button>

        </div>
        <form>
          <div className="conditions-warning-thread">
            {!conditions.isTitleValid && <div>タイトルを入力してください。</div>}
            {!conditions.isGenreSelected && <div>ジャンルを選択してください。</div>}
            {!conditions.isCommentEntered && <div>コメントを入力してください。</div>}
            {!conditions.isUserLoggedIn && <div>ログインしてください。</div>}
          </div>
        </form>
        <div>
          <label>スレッドタイトル:</label>
          <input type="text" value={title} onChange={handleTitleChange} />
        </div>
        <div className='tag-container-thread'>
          <label>スレッドジャンル : </label>
          <select value={genre} onChange={(e) => setGenre(e.target.value)}>
            <option value="">選択してください</option>
            <option value="News">時事ニュース</option>
            <option value="Sports">スポーツ</option>
            <option value="Entertainment">芸能</option>
            <option value="Politics">政治</option>
            <option value="Business">ビジネス・就活</option>
            <option value="Book">本・文学</option>
            <option value="Anime">漫画・アニメ</option>
            <option value="Game">ゲーム</option>
            <option value="Technology">テクノロジー</option>
            <option value="Chat">雑談</option>
          </select>
        </div>
        {isWarningVisible && (
          <div style={{ color: 'red', fontWeight: 'bold' }}>禁止されたワードが含まれています。</div>
        )}
        <div>
          <label>1コメ:</label>
          <textarea className="comment-textarea-thread" value={comment} onChange={handleCommentChange}></textarea>
          <div>{charCount}/500</div> {/* 文字数を表示 */}
        </div>
      </div>
    </div>
  </div>
);

}

export default CreateThread;