import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, Link } from 'react-router-dom'; 
import "./Header.css";
import { auth } from '../../config/firebaseConfig';
import Sitelogo from '../assets/Images/rogo.png';
import EditIcon  from '../assets/Images/edit_white.svg'; 
import LoginIcon from '../assets/Images/login_white.svg';
import LogoutIcon from '../assets/Images/logout_white.svg';
import Sitelogo_Mob from '../assets/Images/rogo_mob_100.png';
import SearchIcon from '../assets/Images/search.svg';
import MenuIcon from '../assets/Images/Menu.svg';
import LoginModal from '../Login/LoginModal'

// モバイルデバイスのブレークポイント定義 (例: 768px)
const mobileBreakpoint = 768;

const Header = ({  onOpenCreateThread,setSearchedArticles,isSidebarVisible,setIsSidebarVisible  }) => {
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState(''); // 記事の検索機能
  // eslint-disable-next-line
  const [results,setResults] = useState([]); // 検索結果を保持
  const [isLoggedIn, setIsLoggedIn] = useState(false);  // ログイン状態を管理
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= mobileBreakpoint);
  const [isSearchVisible, setIsSearchVisible] = useState(!isMobile);
  const [showLoginModal, setShowLoginModal] = useState(false);
    // モバイル表示の際に検索アイコンをクリックしたら検索欄を表示/非表示
  const toggleSearchVisibility = () => {
    setIsSearchVisible(prevState => !prevState);
  };
 
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsLoggedIn(!!user);
    });
    return () => unsubscribe();
  }, []);

// 新しいuseEffect
useEffect(() => {
  const handleResize = () => {
    const newWidth = window.innerWidth;
    const isMobileWidth = newWidth <= mobileBreakpoint;
    setWindowWidth(newWidth);
    setIsMobile(isMobileWidth);
    setIsSearchVisible(!isMobileWidth);
  };

  window.addEventListener('resize', handleResize);
  handleResize(); // コンポーネントがマウントされた時にも実行

  return () => window.removeEventListener('resize', handleResize);
}, []);

  // Firebaseの認証状態を監視
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsLoggedIn(!!user);
    });
    return () => unsubscribe();
  }, []);

  const handleLoginClick = () => {
    if (isLoggedIn) { auth.signOut().then(() => {
      // ログアウト成功
      localStorage.removeItem('jwt');  // JWTを削除
      localStorage.removeItem('userId'); // ユーザーIDを削除
      localStorage.removeItem('userName'); // ユーザー名を削除
      
    }).catch((error) => {
      // エラー処理
      console.error("ログアウトに失敗:", error);
    });
    } else {
      setShowLoginModal(true);
    }
  };

  const performSearch = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_SEARCH_ARTICLE}=${searchTerm}`);
      const data = await response.json();
      if (data.error) {
        throw new Error(data.error);
      }
      setResults(data); // 検索結果をセット
      setSearchedArticles(data); 
    } catch (err) {
      console.error(`Error performing search:`, err);
    }
  };
  const toggleSidebarVisibility = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };
  const closeSidebar = (e) => {
    if (isMobile && isSidebarVisible && !e.target.closest('.sidebar-container') && !e.target.closest('.menu-toggle-button')) {
      setIsSidebarVisible(false);
    }
  };
  const handleDocumentClick = (e) => {
    // 検索コンテナまたは検索ボタンがクリックされた場合は何もしない
    if (e.target.closest('.search-container') || e.target.closest('.search-toggle-button')) {
      return;
    }

    // それ以外の場合は検索欄を非表示にする
    setIsSearchVisible(false);
  };

  // イベントリスナーを追加
  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);
 
  useEffect(() => {
    // モバイル表示の場合のみ、サイドバーを閉じるイベントリスナーを追加
    const eventListener = isMobile ? closeSidebar : null;
    
    if (eventListener) {
      document.addEventListener('click', eventListener);
    }
  
    return () => {
      if (eventListener) {
        document.removeEventListener('click', eventListener);
      }
    };

  }, [isMobile, isSidebarVisible]);

  return (
    <>
      <header className='header-container'>
        <div className='logo-section'>
          <Link to="/">
            <img src={isMobile ? Sitelogo_Mob : Sitelogo} alt="Site Logo" className="site-logo" />
          </Link>
        </div>
        {!isMobile && (
            <div className="search-container">
              <input
                type="text"
                placeholder="Search..."
                className="search-input"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    performSearch();
                  }
                }}
              />
            </div>
          )}


        <div className="button-container">
          {isMobile && (
            <>
              <button className="menu-toggle-button" onClick={toggleSidebarVisibility}>
                <img src={MenuIcon} alt="Menu" />
              </button>
              
              {!isSearchVisible && (
                <button className="search-toggle-button" onClick={() => setIsSearchVisible(true)}>
                  <img src={SearchIcon} alt="Search" />
                </button>
              )}

              {isSearchVisible && (
                <div className="search-container active">
                  <input
                    type="text"
                    placeholder="Search..."
                    className="search-input"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        performSearch();
                      }
                    }}
                  />
                </div>
              )}
            </>
          )}

         

         
            <button className='create-article-button-top' onClick={onOpenCreateThread}>
            {isMobile ? (
              <img src={EditIcon} alt="Edit Icon" className="button-icon" />
              ) : (
              <span className="create-article-button-text">スレッドを作成</span>
            )}
            </button>
          

        <button className='login-button-top' onClick={handleLoginClick}>
          {isLoggedIn ? (
            <>
              {isMobile ? (
                <img src={LogoutIcon} alt="Logout Icon" className="button-icon" />
              ) : (
                <span className="button-text">ログアウト</span>
              )}
            </>
          ) : (
            <>
              {isMobile ? (
                <img src={LoginIcon} alt="Login Icon" className="button-icon" />
                ) : (
                <span className="button-text">サインイン</span>
              )}
            </>
          )}
        </button>
        </div>
      </header>
      {showLoginModal && (
        <LoginModal onClose={() => setShowLoginModal(false)} />
      )}
    </>
  );
};

export default Header;
