import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import './CommentPage.css';
import HeaderComment from '../Header/Header_Comment';
import up_logo from '../assets/Images/thumb_up_white.svg'
import down_logo from '../assets/Images/thumb_down_white.svg'
import CreateComment from '../CreateComment/CreateComment';  
import X_Logo from '../assets/Images/X_logo_black.svg';
import editSquareIcon from '../assets/Images/edit_square_white.svg';
import site_logo from '../assets/Images/giron_Logo_trans.png';
import Page_up from '../assets/Images/arrow_upward.svg';
import Page_down from '../assets/Images/arrow_downward.svg';

let apiBaseUrl;
if (process.env.NODE_ENV === 'development') {
  apiBaseUrl = process.env.REACT_APP_API_DEV_URL;
} else {
  apiBaseUrl = process.env.REACT_APP_API_URL;
}
const CommentPage = () => {
  const [comments, setComments] = useState([]);
  const [articleDetail, setArticleDetail] = useState(null);
  const { articleId } = useParams();
  const [reactions, setReactions] = useState({ agree_count: 0, disagree_count: 0 });
  const [userRating, setUserRating] = useState(null);
  const [isLoggedIn] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [totalComments, setTotalComments] = useState(0);
  const totalReactions = reactions.agree_count + reactions.disagree_count;
  const agreePercentage = totalReactions === 0 ? 50 : (reactions.agree_count / totalReactions) * 100;
  const disagreePercentage = totalReactions === 0 ? 50 : 100 - agreePercentage;
  //const newestComments = [...comments].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  const [showAllComments, setShowAllComments] = useState(false);
  const [oldestComment, setOldestComment] = useState(null);
  const sortedComments = showAllComments 
  ? [...comments].sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
    : [...comments].sort((a, b) => new Date(a.created_at) - new Date(b.created_at)).slice(0, 50);
  const handleShowAllComments = () => {
      setShowAllComments(true);
      window.scrollTo(0, 0); // ページのトップにスクロールする
  };

  const topOfPage = useRef(null);
  const bottomOfPage = useRef(null);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  
  const scrollToBottom = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  };
  useEffect(() => {
    let isMounted = true; // マウント状態を追跡する変数
    

    const fetchComments = async () => {
      try {
        const response = await fetch(`${apiBaseUrl}${process.env.REACT_APP_API_ENDPOINT}${articleId}${process.env.REACT_APP_API_COMMENT}`);
        const data = await response.json();
        if (data.error) {
          throw new Error(data.error);
        }
        if (isMounted) {
         // console.log(data)
          setArticleDetail({
            title: data.title,
            content: data.content // 他のプロパティもこちらでセット
          });
          setTotalComments(data.totalComments);
          setComments(data.comments);
          // totalComments = data.totalComments; // これは下の行に変更
          // ここでtotalCommentsが新たに追加されます。
          // totalCommentsをstateで管理するか、直接render内でdata.totalCommentsを使用
        }
    
        // data.commentsが存在している場合に最も古いコメントをセット
        if (data.comments && data.comments.length > 0) {
          const oldest = data.comments.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))[0];
          setOldestComment(oldest);
        }
      } catch (err) {
        console.error(`Error fetching comments for article ID ${articleId}:`, err);
      }
    };
    
    const fetchUserRating = async () => {
      try {
        const response = await fetch(`${apiBaseUrl}${process.env.REACT_APP_API_ENDPOINT}${articleId}${process.env.REACT_APP_API_GET_USER_REACTION}`);
        const data = await response.json();
        if (data) {
          setUserRating(data);  // 取得した評価情報を状態に保存
         
        }
      } catch (error) {
        console.error("Error fetching user rating:", error);
      }
    };
    const fetchReactions = async () => {
      try {
        const response = await fetch(
          `${apiBaseUrl}${process.env.REACT_APP_API_ENDPOINT}${articleId}${process.env.REACT_APP_API_REACTION}`,
          
          {
            headers: {
              'Cache-Control': 'no-cache'
            }
          }
        );
        const data = await response.json();
        if (data === null || Object.keys(data).length === 0) {
          setReactions({ agree_count: 0, disagree_count: 0 });
        } else if (data.error) {
          throw new Error(data.error);
        } else {
          setReactions(data);
        }
      } catch (err) {
        console.error(`Error fetching reactions for article ID ${articleId}:`, err);
      }
    };

    fetchComments(); //コメント情報を取得
    fetchReactions(); //リアクション数を取得
  //  fetchArticleDetails(); //コメントの詳細を取得
  
    if (true) {  // ログイン機能を実装完了後に修正する if (isLoggedIn) { 
      fetchUserRating();//ユーザーごとの評価情報を取得
    }
    return () => {
      isMounted = false;
  };
  }, [articleId, isLoggedIn]);  
  
  const calculatePercentage = (part, whole) => {
    if (whole === 0) {
      return '0.0';
    }
    return ((part / whole) * 100).toFixed(1);
  };
  const handleOpenModal = () => {
    setShowModal(true);
  };
  useEffect(() => {
    let timer;
    if (isErrorVisible) {
      timer = setTimeout(() => {
        setIsErrorVisible(false);
      }, 3000);
    }
    return () => clearTimeout(timer); // クリーンアップ関数
  }, [isErrorVisible]);
  const handleCloseModal = () => {
    setShowModal(false);
  };
// コメントの日時をフォーマットする関数
const formatDate = (dateString) => {
  // Dateオブジェクトを作成
  const date = new Date(dateString);

  // 日本のタイムゾーンに合わせてフォーマットする
  const formattedDate = date.toLocaleString('ja-JP', {
    timeZone: 'Asia/Tokyo',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  });

  return formattedDate;
};
   // TwitterでシェアするURLを生成する関数
   const createTwitterShareUrl = () => {
    const url = window.location.href; // 現在のページのURL
    const text = `Check out this article: ${articleDetail?.title || ''}`; // ツイートに含めたいテキスト
    const hashtags = "議論"; // ハッシュタグ（カンマ区切りで複数指定可能）

    return `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}&hashtags=${encodeURIComponent(hashtags)}`;
   };

  return (
    <div className="commentPage">
      {/* ページの最上部を指す参照 */}
      <div ref={topOfPage}></div>
      <HeaderComment articleId={articleId} setReactions={setReactions} reactions={reactions} userRating={userRating}/>
      <div className="left-space">
        
        <div className="social-buttons-container">
          <a href={createTwitterShareUrl()} target="_blank" rel="noopener noreferrer">
            <button className="twitter-share-button">
              <img src={X_Logo} alt="Twitterでシェアする" />
            </button>
          </a>
          <a href="/" className="site-icon-link">
            <img src={site_logo} alt="Site Icon" />
          </a>
          {/* ページ上部へのスクロールボタン */}
          <button onClick={scrollToTop} className="scroll-button">
            <img src={Page_up} alt="ページの一番上に移動する" />
          </button>
          {/* ページ下部へのスクロールボタン */}
          <button onClick={scrollToBottom} className="scroll-button">
            <img src={Page_down} alt="ページの一番下に移動する" />
          </button>
        </div>
      </div>
      <div className="commentList">
        {articleDetail && (
          <>
            <h1>{articleDetail.title}</h1>
            <div>{articleDetail.content}</div>
          </>
        )}
        {oldestComment ? (
          <div className="first-comment">
            <h2>1コメ</h2>
            <div>{oldestComment.comment}</div>
          </div>
        ) : null}
        <h1 className="Bar_Title">投票結果</h1>
        
        {reactions && (
          <div className="approvalBar">
            <div className="bar-container">
              <div className="bar agree" style={{width: `${agreePercentage}%`}}>
                <img src={up_logo} alt="Agree" />
                  {reactions.agree_count} (
                  {calculatePercentage(reactions.agree_count, totalReactions)}%
                  )
              </div>
            <div className="bar disagree" style={{width: `${disagreePercentage}%`}}>
              <img src={down_logo} alt="Disagree" />
              {reactions.disagree_count} (
              {calculatePercentage(reactions.disagree_count, totalReactions)}%
              )
            </div>
          </div>
        </div>
      )}
         
        {sortedComments.length > 1 ? (
                <div>
                <h2>コメント一覧</h2>
                {sortedComments.map((comment, index) => (
                  <div key={comment.comment_id} className="comment-container">
                    <div className="comment-header">
                      {/* コメント番号（No.）の動的計算 */}
                      <span className="comment-number">No.{totalComments - sortedComments.length + index + 1}</span>
                      
                      <span className="comment-user">名無しの{comment.username || 'Anonymous'}</span>
                      <span className="comment-timestamp">作成日時{formatDate(comment.created_at) || 'Unknown'}</span>
                    </div>
                    <div className="comment-body">
                      {comment.comment}
                    </div>
                  </div>
                ))}
                 
                 
              </div>
        ) : (
          <div>
            <h2 className='Not_Comment'>コメントがありません</h2>
          </div>
        )}
        <button onClick={handleShowAllComments}>すべてのコメントを表示する</button>
      </div>
      <div className="right-space">
        
       
        
      </div>
      {!showModal && (
       <button
        className="create-comment-button"
        onClick={handleOpenModal}
        >
        <img src={editSquareIcon} alt="コメント作成" className="create-comment-icon" />
        
      </button>
      )}

      {showModal && (
        <CreateComment 
          articleId={articleId} 
          onClose={handleCloseModal} 
          setIsErrorVisible={setIsErrorVisible} 
        />
      )}
      {isErrorVisible && (
        <div className="error-popup">
          <div>コメント入力に失敗しました。</div>
        </div>
      )}


      {/* ページの最下部を指す参照 */}
      <div ref={bottomOfPage}></div>
    </div>
    
  );
      };
export default CommentPage;
