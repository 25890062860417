import React from 'react';
import { signInWithGoogle, signInWithTwitter } from './Login';
import './LoginModal.css';

const LoginModal = ({ onClose }) => {
  const handleSignInWithGoogle = () => {
    signInWithGoogle()
      .then(() => onClose())  // ログイン成功時にモーダルを閉じる
      .catch((error) => console.error("Googleログインエラー:", error));
  };

  const handleSignInWithTwitter = () => {
    signInWithTwitter()
      .then(() => onClose())  // ログイン成功時にモーダルを閉じる
      .catch((error) => console.error("Twitterログインエラー:", error));
  };

  return (
    <div className="login-modal">
      <button className="login-button_google" onClick={handleSignInWithGoogle}>Googleでログイン</button>
      <button className="login-button_twitter" onClick={handleSignInWithTwitter}>Xでログイン</button>
      <button className="close-button" onClick={onClose}>閉じる</button>
    </div>
  );
};

export default LoginModal;
