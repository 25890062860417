import React, { useState } from 'react';
import "./CreateComment.css";
import SendWriteIcon from '../assets/Images/send_write.svg'; 

// NGワードリスト
const bannedWords = ["殺す", "ころす", "コロス", "死ね","チンコ", "ちんこ", "まんこ", "マンコ"];

//文字数制限
const CharGard=500;


const CreateComment = ({ articleId,onClose,setIsErrorVisible}) => {
  const initialUsername = localStorage.getItem('userName') || '名無し';
  const [username] = useState(initialUsername);
  const [newComment, setNewComment] = useState('');
// eslint-disable-next-line no-unused-vars
  const [isWarningVisible, setIsWarningVisible] = useState(false);
  const [charCount, setCharCount] = useState(0); //コメント文字数を制限
  const userId = localStorage.getItem('userId');
  


  //警告のステータス管理
const checkConditions = () => {
  return {
    isUserLoggedIn: userId && userId !== "undefined",
    isCommentEntered: newComment.trim() !== '',
    isCommentLengthValid: charCount <= CharGard,
    isCommentSafe: !bannedWords.some((word) => newComment.includes(word)),
  };
};
  const handleCommentChange = (e) => {
    const comment = e.target.value;
    setCharCount(comment.length); // 文字数を更新
    if (bannedWords.some((word) => comment.includes(word))) {
      setIsWarningVisible(true);
      return;
    }
    setIsWarningVisible(false);
    setNewComment(comment);
  };
  

  const handleSubmit = () => {
 // ユーザーがログインしているかチェック
    const userId = localStorage.getItem('userId');
    if (!userId || userId === "undefined") {
      console.error('ログインしてください');
      setIsErrorVisible(true); // エラーメッセージを表示
      return;
    }

    if (!isWarningVisible && charCount <= CharGard){
        //console.log("Username: ", username);
        //console.log("New Comment: ", newComment);
        sendCommentToServer();
        onClose();
    }
  };
  const sendCommentToServer = async () => {
    try {
      const jwtToken = localStorage.getItem('jwt');
      const response = await fetch(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_CREATE_COMMENT_ENDPOINT}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${jwtToken}`, 
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          articleId: articleId, // この値は適切なものに置き換えてください
          userId: userId,
          comment: newComment,
        }),
      });
  
      if (response.ok) {
        const data = await response.json();
        //console.log('Comment sent successfully:', data);
        setIsErrorVisible(false); // エラーメッセージを非表示に
        onClose(); // モーダルを閉じる
      } else {
        const data = await response.json();
        console.error('Failed to send comment:', data);
        setIsErrorVisible(true); // エラーメッセージを表示
      }
    } catch (error) {
      console.error('Error sending comment:', error);
      setIsErrorVisible(true); // エラーメッセージを表示
    }
    const handleSubmit = async () => {
      const conditions = checkConditions();
  
      if (!conditions.isCommentEntered || !conditions.isUserLoggedIn) {
        setIsErrorVisible(true); // エラーメッセージを表示
        return;
      }
    }
    
  };
  const conditions = checkConditions();
  return (
    <div className="comment-modal-overlay">
    <div className="comment-modal">
      <div className="comment-modal-header">
        <span className="comment-close-button" onClick={onClose}>&times;</span>
        <button
          className={
            conditions.isCommentEntered && conditions.isCommentLengthValid && conditions.isCommentSafe
            ? "comment-create-button"
            : "comment-create-button-disabled"
            }
          onClick={handleSubmit}
          disabled={!conditions.isCommentEntered || !conditions.isCommentLengthValid || !conditions.isCommentSafe}
        >
        <img src={SendWriteIcon} alt="Send" />
      </button>
      </div>
      <form className="comment-modal-form">
        {isWarningVisible && (
          <div className="comment-warning-text">禁止されたワードが含まれています。</div>
        )}
        <div className="comment-conditions-warning">
          {!conditions.isCommentEntered && <div>■コメントを入力してください。</div>}
          {!conditions.isUserLoggedIn && <div>■ログインしてください。</div>}
        </div>
        <label>名前:</label>
        <input type="text" value={`名無し(${username})`} readOnly />

        <label>コメント:</label>
        <textarea className="comment-comment-textarea" value={newComment} onChange={handleCommentChange}></textarea>
        <div>{charCount}/500</div> {/* 文字数を表示 */}

       
      </form>
    </div>
  </div>
  );
};

export default CreateComment;
