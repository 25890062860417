import React, { useState, useEffect, useCallback } from 'react';

import { Link } from 'react-router-dom';
import './Sidebar.css';
import Home_logo from '../assets/Images/cottage.svg'; 
import Latest_logo from '../assets/Images/language.svg'; 
import News_logo from '../assets/Images/newsmode.svg'; 
import Sports_logo from '../assets/Images/sports_baseball.svg'; 
import Game_logo from '../assets/Images/stadia.svg';
import Biz_logo from '../assets/Images/storefront.svg'; 
import Tech_logo from '../assets/Images/smart.svg'; 
import Stars_logo from '../assets/Images/stars.svg'; 
import Us_logo from '../assets/Images/front_hand.svg'; 
import Contact_logo from '../assets/Images/forward_to_inbox.svg'; 
import Info_logo from '../assets/Images/info.svg'; 
import Poli_logo from '../assets/Images/polittics.svg'
import Book_logo from '../assets/Images/book.svg'
import Manga_logo from '../assets/Images/manga.svg'
import Chat_logo from '../assets/Images/chat.svg'
import Menu_logo from '../assets/Images/Menu.svg'

const Sidebar = ({ isSidebarVisible, setIsSidebarVisible, setSelectedGenre  }) => {
    // useStateを使用してwindowWidthの状態を設定します。
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    // useEffectを使用してリサイズイベントを監視し、windowWidthの状態を更新します。
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const mobileBreakpoint = 768;
  const isMobile = windowWidth <= mobileBreakpoint;
// サイドバー以外の場所がクリックされたときにサイドバーを閉じる関数
const toggleSidebar = () => {
  setIsSidebarVisible(!isSidebarVisible); // サイドバーの表示/非表示をトグルする
};

const handleClick = (genre) => {
  setSelectedGenre(genre);

  if (windowWidth <= mobileBreakpoint) {
    setIsSidebarVisible(false);
  }
};



  return (

    <div className={`sidebar-container ${isSidebarVisible ? '' : 'sidebar-container-hidden'}`}>
        {isMobile && (
          <button onClick={toggleSidebar}>
           <img src={Menu_logo} alt="Menu" />
         </button>
        )}
      <div className={`sidebar-container ${isSidebarVisible ? '' : 'sidebar-container-hidden'}`}>

      
        <div className="sidebar-item" onClick={() => handleClick('Home')}>
          <img src={Home_logo} alt="Icon" className="sidebar-icon" />
          <span className="sidebar-text">Home</span>
        </div>
        <div className="sidebar-item" onClick={() => handleClick('Latest')}>
          <img src={Latest_logo} alt="Icon" className="sidebar-icon" />
          <span className="sidebar-text">最新</span>
        </div>
        <div className="sidebar-item" onClick={() => handleClick('News')}>
          <img src={News_logo} alt="Icon" className="sidebar-icon" />
          <span className="sidebar-text">時事ニュース</span>
        </div>
        <div className="sidebar-item" onClick={() => handleClick('Sports')}>
          <img src={Sports_logo} alt="Icon" className="sidebar-icon" />
          <span className="sidebar-text">スポーツ</span>
        </div>
        <div className="sidebar-item" onClick={() => handleClick('Entertainment')}>
          <img src={Stars_logo} alt="Icon" className="sidebar-icon" />
          <span className="sidebar-text">芸能</span>
        </div>
        <div className="sidebar-item" onClick={() => handleClick('Politics')}>
          <img src={Poli_logo} alt="Icon" className="sidebar-icon" />
          <span className="sidebar-text">政治</span>
        </div>
        <div className="sidebar-item" onClick={() => handleClick('Business')}>
          <img src={Biz_logo} alt="Icon" className="sidebar-icon" />
          <span className="sidebar-text">ビジネス・就活</span>
        </div>
        <div className="sidebar-item" onClick={() => handleClick('Book')}>
          <img src={Book_logo} alt="Icon" className="sidebar-icon" />
          <span className="sidebar-text">本・文学</span>
        </div>
        <div className="sidebar-item" onClick={() => handleClick('Anime')}>
          <img src={Manga_logo} alt="Icon" className="sidebar-icon" />
          <span className="sidebar-text">漫画・アニメ</span>
        </div>
        <div className="sidebar-item" onClick={() => handleClick('Game')}>
          <img src={Game_logo} alt="Icon" className="sidebar-icon" />
          <span className="sidebar-text">ゲーム</span>
        </div>
        <div className="sidebar-item" onClick={() => handleClick('Technology')}>
          <img src={Tech_logo} alt="Icon" className="sidebar-icon" />
          <span className="sidebar-text">テクノロジー</span>
        </div>
        <div className="sidebar-item boundary-line" onClick={() => handleClick('Chat')}>
          <img src={Chat_logo} alt="Icon" className="sidebar-icon" />
         <span className="sidebar-text">雑談</span>
        </div>

      <div className="sidebar-item">
        <Link to="/about-us">
          <img src={Us_logo} alt="Icon" className="sidebar-icon" />
          <span className="sidebar-text">About us</span>
        </Link>
      </div>
      <div className="sidebar-item">
        <Link to="/warnings">
          <img src={Info_logo} alt="Icon" className="sidebar-icon" /> 
          <span className="sidebar-text">注意事項</span>
        </Link>
      </div>
      <div className="sidebar-item">
      <Link to="/contact-us">
        <img src={Contact_logo} alt="Icon" className="sidebar-icon" />
        <span className="sidebar-text">連絡先</span>
      </Link>
      </div>
    </div>
    </div>
  );
};

export default Sidebar;