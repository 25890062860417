import React, { useState } from 'react';
import './ContactUs.css'; // CSSをインポート

const ContactUs = () => {
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const userId = localStorage.getItem('userId');

  const handleSubmit = async () => {
    if (!userId || userId === "undefined") {
      console.error('ログインしていません。');
      return;
    }

    // ここでAPIを呼び出してデータを送信
    try {
      const url = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_CONTACT_US}`; // APIエンドポイントを適切に設定してください
      const jwtToken = localStorage.getItem('jwt');
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${jwtToken}`, 
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          subject,
          message,
          userId
        })
      });

      if (response.status === 201) {
        console.log("問い合わせが送信されました:", { subject, message });
      }
    } catch (error) {
      console.error('問い合わせの送信に失敗:', error);
    }
  };

  return (
    <div className="contact-us-container">
      <h1>連絡先</h1>

      <section className="contact-section">
        <h2>問い合わせ</h2>
        <p>
          何かご不明点、ご意見、ご要望があれば、以下からお知らせください。<br />
          件名には以下のテンプレートを使用してください。<br />
          【削除依頼】 : 書き込みコメント・スレッドの削除依頼(スレッド名・書き込まれたコメントのusername・作成日時を書いておいてもらえると対応しやすいです)<br />
          【不具合報告】 : 致命的または機能的な不具合の報告(具体的に書いていただけると助かります)<br />
          【要望】 : 機能やサービスの変更・要望(反映されるかはわかりません)<br />
          【その他】 : 何か連絡を取りたいときに使用してください<br />
          ※対応に数週間から数か月掛かるケースがあります。

        </p>
        <div>
          <label>件名:</label>
          <input type="text" value={subject} onChange={(e) => setSubject(e.target.value)} />
        </div>
        <div>
          <label>本文:</label>
          <textarea value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
        </div>
        {subject.length > 0 && message.length > 0 && (
          <button 
            type="button" 
            className={`send-button ${userId ? 'active' : 'inactive'}`} 
            onClick={handleSubmit} 
            disabled={!userId || userId === "undefined"}
          >
            送信
          </button>
        )}
      </section>
    </div>
  );
};

export default ContactUs;