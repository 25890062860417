import { TwitterAuthProvider,signInWithPopup } from 'firebase/auth';
import { auth, provider } from '../../config/firebaseConfig';
import jwtDecode from 'jwt-decode';

let apiBaseUrl;

if (process.env.NODE_ENV === 'development') {
  apiBaseUrl = process.env.REACT_APP_API_DEV_URL;
} else {
  apiBaseUrl = process.env.REACT_APP_API_URL;
}

const authGoogleEndpoint = `${apiBaseUrl}${process.env.REACT_APP_API_AUTH_ENDPOINT}${process.env.REACT_APP_API_AUTH_GOOGLE}`;
const authTwitterEndpoint = `${apiBaseUrl}${process.env.REACT_APP_API_AUTH_ENDPOINT}${process.env.REACT_APP_API_AUTH_TWITTER}`;

export const signInWithGoogle = () => {
  return new Promise((resolve, reject) => {
    signInWithPopup(auth, provider)
      .then(async (result) => {
        if (result && result.user) {
          const idToken = await result.user.getIdToken();
          return fetch(authGoogleEndpoint, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ idToken })
          })
          .then(response => response.json())
          .then(data => {
             // レスポンスの内容をログに出力
             
            if (data && data.token) {
              try {
                const decodedToken = jwtDecode(data.token);
                if (decodedToken.userId && decodedToken.userName) {  // userIdとuserNameが存在するか確認
                  localStorage.setItem('jwt', data.token);
                  localStorage.setItem('userId', decodedToken.userId);
                  localStorage.setItem('userName', decodedToken.userName);
                  
                } else {

                  console.error("UserId or UserName missing in decoded token");
                }

              } catch (error) {
                console.error('Invalid token:', error);
              }
            } else {
              console.error('No token provided');
            }
            resolve(result);  // Promiseを解決
          });
        } else {
          console.error('ID Token is missing');
          throw new Error('ID Token is missing');
        }
      })
      .catch((error) => {
        console.error(error);
        reject(error);  // Promiseを拒否
        alert("ログインに失敗しました: ");
        // 今までの処理がなかったことにする（ローカルストレージからデータを削除）
        localStorage.removeItem('jwt');
        localStorage.removeItem('userId');
        localStorage.removeItem('userName');
      });
  });
};
export const signInWithTwitter = () => {
  return new Promise((resolve, reject) => {
    const provider = new TwitterAuthProvider();

    signInWithPopup(auth, provider)
      .then(async (result) => {
        //console.log('Twitter認証の結果:', result); 
        // Twitterログイン成功
        if (result && result.user) {
          // FirebaseからのIDトークンを取得
          const idToken = await result.user.getIdToken();
          //console.log('取得したIDトークン:', idToken); 
          // バックエンドサーバーへの認証リクエストを送信
          return fetch(authTwitterEndpoint, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ idToken })
          })
          .then(response => response.json())
          .then(data => {
            // トークンの検証とユーザー情報の保存
            if (data && data.token) {
              try {
                const decodedToken = jwtDecode(data.token);
                if (decodedToken.userId && decodedToken.userName) {  // userIdとuserNameが存在するか確認
                  localStorage.setItem('jwt', data.token);
                  localStorage.setItem('userId', decodedToken.userId);
                  localStorage.setItem('userName', decodedToken.userName);
                } else {
                  console.error("UserId or UserName missing in decoded token");
                }
              } catch (error) {
                console.error('Invalid token:', error);
              }
            } else {
              console.error('No token provided');
            }
            resolve(result);
          });
        } else {
          console.error('ID Token is missing');
          throw new Error('ID Token is missing');
        }
      })
      .catch((error) => {
        // エラーハンドリング
        console.error(error);
        reject(error);
        alert("ログインに失敗しました: ");
        // エラー時の処理
        localStorage.removeItem('jwt');
        localStorage.removeItem('userId');
        localStorage.removeItem('userName');
      });
  });
};