import React, { useState ,useEffect} from 'react'; 
import './Header_Comment.css';
import up_logo from '../assets/Images/thumb_up.svg';
import down_logo from '../assets/Images/thumb_down.svg';
import high_logo from '../assets/Images/local_fire.svg';
import up_logo_color from '../assets/Images/thumb_up_blue.svg';
import down_logo_color from '../assets/Images/thumb_down_red.svg';
import high_logo_color from '../assets/Images/local_fire_orange.svg';
import { signInWithGoogle } from '../Login/Login';
import { auth } from '../../config/firebaseConfig';
import Sitelogo from '../assets/Images/rogo.png';
import { Link } from 'react-router-dom';
import LoginIcon from '../assets/Images/login_white.svg'; 
import LogoutIcon from '../assets/Images/logout_white.svg'; 
import LoginModal from '../Login/LoginModal'


const HeaderComment = ({ articleId, setReactions, reactions,userRating }) => {
  //const [shouldFetch, setShouldFetch] = useState(false); // 新たに追加するステート変数
  const [isLoading, setIsLoading] = useState(false); // 連打防止用
  const [lastVoteType, setLastVoteType] = useState(null); // 最後に投票したタイプ
  const [upvoteStatus, setUpvoteStatus] = useState('neutral'); // neutral, upvoted
  const [downvoteStatus, setDownvoteStatus] = useState('neutral'); // neutral, downvoted
  const [highRatingStatus, setHighRatingStatus] = useState('neutral'); //neutral, "UPVOTE"
  const [isLoggedIn, setIsLoggedIn] = useState(false);  // ログイン状態を管理
  const [isSidebarVisible, setIsSidebarVisible] = useState(false); 
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const isMobile = windowWidth <= 768; // 768px をモバイルのブレークポイントとします
  const [showLoginModal, setShowLoginModal] = useState(false);

  
  useEffect(() => {
    if (isLoggedIn && userRating) {
      // ログインしている場合、userRating に基づいてボタンの状態を設定
      if (userRating.agreeDisagree === 'AGREE') {
        setUpvoteStatus('AGREE'); //賛成
      } else {
        setUpvoteStatus('neutral');
      }
      if (userRating.agreeDisagree === 'DISAGREE') {
        setDownvoteStatus('DISAGREE'); //反対
      } else {
        setDownvoteStatus('neutral');
      }
      if (userRating.upDown === 'UPVOTE') {
        setHighRatingStatus('upvoted'); //高評価
      } else {
        setHighRatingStatus('neutral');
      }
    } else {
      // ログインしていない場合、ボタンの状態をリセット
      setUpvoteStatus('neutral');
      setDownvoteStatus('neutral');
      setHighRatingStatus('neutral');
    }
  }, [isLoggedIn, userRating]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsLoggedIn(!!user);
    });
    return () => unsubscribe();
  }, []);
  useEffect(() => {
    if (!isLoggedIn) {
      // ログインしていない場合、ボタンの状態をリセット
      setUpvoteStatus('neutral');
      setDownvoteStatus('neutral');
      setHighRatingStatus('neutral');
    }
  }, [isLoggedIn]);

 const handleLoginClick = () => {
    if (isLoggedIn) { auth.signOut().then(() => {
      // ログアウト成功
      localStorage.removeItem('jwt');  // JWTを削除
      localStorage.removeItem('userId'); // ユーザーIDを削除
      localStorage.removeItem('userName'); // ユーザー名を削除
      
    }).catch((error) => {
      // エラー処理
      console.error("ログアウトに失敗:", error);
    });
    } else {
      setShowLoginModal(true);
    }
  };

  const handleVote = async (voteType) => {

   
    const userId = localStorage.getItem('userId');
    
    if (!userId || userId === "undefined") {
      alert('ログインしてください');
      return;
    }

    if (isLoading) return; // 既に処理中ならば何もしない
    setIsLoading(true); // 処理開始
    try {
      if (lastVoteType === voteType) {
        if (voteType === 'AGREE') setUpvoteStatus('neutral');
        if (voteType === 'DISAGREE') setDownvoteStatus('neutral');
      } else {
        if (voteType === 'AGREE') {
          setUpvoteStatus('AGREE');
          setDownvoteStatus('neutral');
        }
        if (voteType === 'DISAGREE') {
          setDownvoteStatus('DISAGREE');
          setUpvoteStatus('neutral');
        }
      }
      let newReactions = { ...reactions };
      // Optimistic UI Update
      if (lastVoteType === voteType) {
        // 同じボタンが連続で押された場合、その投票は取り消される
        if (voteType === 'AGREE') newReactions.agree_count = Math.max(0, newReactions.agree_count - 1);
        if (voteType === 'DISAGREE') newReactions.disagree_count = Math.max(0, newReactions.disagree_count - 1);
        setLastVoteType(null); // リセット
      } else {
        if (lastVoteType === 'AGREE' && voteType === 'DISAGREE') {
          newReactions.agree_count = Math.max(0, newReactions.agree_count - 1);
          newReactions.disagree_count++;
        }else if (lastVoteType === 'DISAGREE' && voteType === 'AGREE') {
          newReactions.disagree_count = Math.max(0, newReactions.disagree_count - 1);
          newReactions.agree_count++;
        }else {
          if (voteType === 'AGREE') newReactions.agree_count++;
          if (voteType === 'DISAGREE') newReactions.disagree_count++;
        }
        setLastVoteType(voteType); // 最後に投票したタイプを更新
      }

  
      setReactions(newReactions);


      // APIを呼び出してバックエンドで投票を更新
      const jwtToken = localStorage.getItem('jwt');
      const userId = localStorage.getItem('userId');
      const voteEndpoint = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_VOTE_ENDPOINT}`;
      const response = await fetch(voteEndpoint, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${jwtToken}`, 
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ articleId, voteType, userId }), 
      });
  
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'An error occurred');
      }
  
       // 最後に押されたボタンを更新
    if (lastVoteType !== voteType) {
      setLastVoteType(voteType);
    }
    } catch (error) {
      console.error('Error voting:', error);
      // エラーが発生した場合はロールバック
      setReactions({ ...reactions });
    } finally {
      setIsLoading(false); // 処理終了
    }
  };
  const handleHighRating = async () => {
    const userId = localStorage.getItem('userId'); // 追加: ローカルストレージからuserIdを取得
    if (!userId || userId === "undefined") {
      alert('ログインしてください'); // 追加: ログインしていない場合にアラートを表示
      return;
    }
    setIsLoading(true);  // ローディング状態を開始

    try {
      const jwtToken = localStorage.getItem('jwt');
      const highRatingEndpoint = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_HIGH_RATING_ENDPOINT}${articleId}${process.env.REACT_APP_TOGGLE_HIGH_RATING_ENDPOINT}`;
      const response = await fetch(highRatingEndpoint, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${jwtToken}`, 
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: userId, 
        }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'An error occurred');
      }


      // ここでフロントエンドの状態を更新する処理を書くこともできます
        // 高評価状態を切り替え
    if (highRatingStatus === 'neutral') {
      setHighRatingStatus('upvoted');
    } else {
      setHighRatingStatus('neutral');
    }
      //console.log('High rating incremented:', data);

    } catch (error) {
      console.error('Error updating high rating:', error);
    } finally {
      setIsLoading(false);  // ローディング状態を終了
    }
  };
  const displayedUpLogo = upvoteStatus === 'AGREE' ? up_logo_color : up_logo;
  const displayedDownLogo = downvoteStatus === 'DISAGREE' ? down_logo_color : down_logo;
  const displayedHighRatingLogo = highRatingStatus === 'upvoted' ? high_logo_color : high_logo;
  
  useEffect(() => {
    // ウィンドウのサイズが変更された時に呼び出される関数
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
  
    // イベントリスナーを追加
    window.addEventListener('resize', handleResize);
  
    // コンポーネントのアンマウント時にイベントリスナーを削除
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="header-container-comment">
      <div className='logo-section'>
        <Link to="/">  {/* Link コンポーネントで囲む */}
          <img src={Sitelogo} alt="Site Logo" className="site-logo-comment" />
        </Link>
      </div>
      <div className="reaction-buttons-comment">
        <button disabled={isLoading} className="reaction-button thumb-up" onClick={() => handleVote('AGREE')}>
          <img src={displayedUpLogo} alt="Thumb Up" />
        </button>
        <button disabled={isLoading} className="reaction-button thumb-down" onClick={() => handleVote('DISAGREE')}>
          <img src={displayedDownLogo} alt="Thumb Down" />
        </button>
        <button disabled={isLoading} className="reaction-button high-rating" onClick={handleHighRating}>
          <img src={displayedHighRatingLogo} alt="High Rating" />
        </button>
      </div>
      <div className="login-section-comment">
        <button className="login-button-comment" onClick={handleLoginClick}>
          {isLoggedIn ? (
          <>
            {isMobile ? null : <span className="button-text-comment">ログアウト</span>}
            <img src={isMobile ? LogoutIcon : LoginIcon} alt="Login/Logout Icon" className="button-icon-comment" />
          </>
          ) : (
          <>
            {isMobile ? null : <span className="button-text-comment">サインイン</span>}
            <img src={LoginIcon} alt="Login Icon" className="button-icon-comment" />
          </>
          )}
        </button>
      </div>
      {showLoginModal && (
        <LoginModal onClose={() => setShowLoginModal(false)} />
      )}
      <div></div> {/* 空の div を追加 */}
    </div>
  );
  
};

export default HeaderComment;
